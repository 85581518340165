import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

import {
  getAllWaypoints as getAllWaypointsAction,
  getAllWaypointTypes as getAllWaypointTypesAction,
  getAllUserWaypoints as getAllUserWaypointsAction,
} from 'redux/actions/waypointActions';
import { loadUserRoutes as loadUserRoutesAction } from 'redux/actions/routeActions';
import { apiUrl } from 'services/webclient';
import { magneticBearing, decimalToDMS } from 'utils/map';
import { isMobileWidth } from 'utils/screen';
import metrics from 'utils/metrics';

import MapComponent from './components/Map';
import MapNav from './components/MapNav/MapNav';
import WaypointInputSearch from './components/WaypointInputSearch/WaypointInputSearch';

import { addRouteWaypoint, switchRouteWaypoint } from '../../redux/actions/routeActions';
import { getActiveRoute } from '../../redux/selectors/route';

import RoutePanel from './components/RoutePanel/RoutePanel';
import { withDrawer } from '../../context/MainContextDrawer';
import UserRoutes from './components/UserRoutes/UserRoutes';
import Configs from './components/Configs/Configs';
import News from './components/News/News';
import AppMobile from './components/AppMobile/AppMobile';
import MapOptions from './components/MapOptions/MapOptions';
import MeteorologyOptions from './components/MeteorologyOptions/MeteorologyOptions';
import MeteorologySlider from './components/MeteorologySlider/MeteorologySlider';
import MapSearch from './components/MapSearch/MapSearch';
import MapTip from './components/MapTip/MapTip';
import AppFooter from './components/AppFooter/AppFooter';
import AppModalDesktop from './components/AppModalDesktop/AppModalDesktop';
import AppModalMobile from './components/AppModalMobile/AppModalMobile';

import DashboardTour from './components/DashboardTour/DashboardTour';
import withTourContext from '../../context/TourContext/withTourContext';

import PromoModal from './components/PromoModal/PromoModal';

import ImageBanner from './assets/promo/pilotapp2022.png';

import './MapMain.style.scss';
import { NexMarket } from './components/NexMarket/NexMarket';
import BetaBanner from './components/BetaBanner/BetaBanner';
import { BetaInfo } from './components/BetaInfo/BetaInfo';

export const OPTIONS_TYPE = {
  METEOROLOGY: 'METEOROLOGY',
  LAYERS: 'LAYERS',
};

export const METEOROLOGY_OPTIONS = {
  CLOUDS: 'clouds',
  WINDS: 'wind',
  RAIN: 'rain',
  TEMPERATURE: 'temperature',
};

class MapMain extends Component {
  lastRouteActive = null;

  mapRef = null;

  state = {
    center: [-15, -50],
    zoom: 5,
    bounds: [
      [-33.63, -74.37],
      [5.92, -34.73],
    ],

    meteorology: null,
    meteorologyPicker: null,
    meteorologyLevel: null,

    mapSearchActive: false,
    mapSearchText: '',
    mapSearchWaypointActive: null,
    mapSearchWaypoint: null,
    mapSearchLatLng: null,
    mapSearchXY: null,

    ruleActive: false,
    ruleDragging: false,
    ruleStartLatLng: null,
    ruleEndLatLng: null,

    blockMapClick: false,

    mapNavOpen: null,
    baseMap: null,
    chartOptionsFirst: null,
    chartOptionsSecond: 'WAC',

    appFooter: true,
    appModalDesktop: false,
    appModalMobile: true,
    betaBanner: true,
  };

  componentDidMount() {
    this.props.getAllWaypointsAction();
    this.props.getAllUserWaypointsAction();
    this.props.getAllWaypointTypesAction();
    this.props.loadUserRoutesAction();

    this.promoConditions = false;

    if (this.promoConditions) {
      ReactPixel.trackCustom('ModalPromoView', { promotion: 'piloto_aluno' });
      metrics.collect(metrics.events.MAPA.PROMO, 'piloto_aluno');
      LinkedInTag.track('4613721');
    }

    metrics.collect(metrics.events.MAPA.PAGE);
    LinkedInTag.track('4613737');
  }

  componentDidUpdate() {
    const { activeRoute } = this.props;

    if (activeRoute && this.lastRouteActive !== activeRoute.id && activeRoute.waypoints.length > 1) {
      this.handleFitRoute();
      this.lastRouteActive = activeRoute.id;
    }
  }

  handleMapRef = ref => {
    this.mapRef = ref;
  };

  shouldComponentUpdate = newProps => {
    if (this.props.drawer.drawerRoute !== newProps.drawer.drawerRoute || this.props.drawer.open !== newProps.drawer.open) {
      this.setState({
        mapSearchActive: false,
        mapSearchWaypoint: false,
        mapSearchLatLng: null,
        mapSearchXY: null,
        mapSearchWaypointActive: null,
      });
    }

    return true;
  };

  handleMapClick = event => {
    const { tourContext } = this.props;

    if (tourContext.isDisabled(metrics.events.MAPA.THIS)) {
      return;
    }

    tourContext.dispatchTourClick(metrics.events.MAPA.REGUA);

    if (this.state.blockMapClick) {
      return;
    }

    const { lat, lng } = event.latlng;
    const { clientX, clientY } = event.originalEvent;
    const { ruleActive, mapSearchWaypoint, mapSearchActive } = this.state;

    // RULE
    if (ruleActive) {
      this.handleRule({ lat, lng });
      return;
    }

    if (mapSearchWaypoint) {
      mapSearchWaypoint.target.setLatLng({
        lat: mapSearchWaypoint.waypoint.latitude,
        lng: mapSearchWaypoint.waypoint.longitude,
      });

      this.setState({
        mapSearchActive: false,
        mapSearchWaypoint: false,
        mapSearchLatLng: null,
        mapSearchXY: null,
        mapSearchWaypointActive: null,
        mapNavOpen: null,
      });

      return;
    }

    if (mapSearchActive) {
      this.setState({
        mapSearchActive: false,
        mapSearchText: '',
        mapSearchWaypoint: false,
        mapSearchLatLng: null,
        mapSearchXY: null,
        mapSearchWaypointActive: null,
        mapNavOpen: null,
      });
      return;
    }

    if (this.state.mapNavOpen === null && this.state.mapSearchText === '') {
      this.setState({
        mapSearchActive: true,
        closeWaypointSearch: true,
        mapSearchLatLng: { lat, lng },
        mapSearchXY: { x: clientX, y: clientY },
        mapSearchWaypointActive: null,
        mapNavOpen: null,
      });
    }

    this.setState({ mapNavOpen: null, mapSearchText: '' });
  };

  handleMeteorologyMapClick = event => {
    if (this.state.blockMapClick) {
      return;
    }

    const { lat, lng } = event.latlng;

    this.setState({ meteorologyPicker: [lat, lng], mapNavOpen: null });
  };

  handleRule = ({ lat, lng }) => {
    const { ruleStartLatLng, ruleDragging, ruleEndLatLng } = this.state;

    if (!ruleStartLatLng) {
      // RULE START
      this.setState({ ruleStartLatLng: [lat, lng], ruleDragging: true });
    } else if (ruleStartLatLng && ruleDragging) {
      // RULE END
      this.setState({ ruleEndLatLng: [lat, lng], ruleDragging: false });
    } else if (ruleStartLatLng && ruleEndLatLng && !ruleDragging) {
      // RULE RESET
      this.setState({ ruleStartLatLng: [lat, lng], ruleDragging: true, ruleEndLatLng: null });
    }
  };

  handleMapMouseMove = ({ lat, lng }) => {
    const { ruleActive, ruleDragging } = this.state;

    if (ruleActive && ruleDragging) {
      this.setState({ ruleEndLatLng: [lat, lng] });
    }
  };

  handleRuleUpdate = ruleNewState => {
    if (ruleNewState) {
      this.setState({
        ruleActive: ruleNewState,
        mapSearchActive: false,
        mapSearchLatLng: null,
        mapSearchXY: null,
        mapSearchWaypoint: null,
        mapSearchWaypointActive: null,
        mapNavOpen: null,
      });
    } else {
      this.setState({ ruleActive: ruleNewState, ruleStartLatLng: null, ruleEndLatLng: null, mapNavOpen: null });
    }
  };

  handleRuleAddToMap = () => {
    const { activeRoute, addRouteWaypoint } = this.props;
    const { ruleEndLatLng } = this.state;

    if (ruleEndLatLng) {
      addRouteWaypoint(
        {
          id: null,
          code: null,
          latitude: ruleEndLatLng[0],
          longitude: ruleEndLatLng[1],
          name: `${decimalToDMS(ruleEndLatLng[0])} ${decimalToDMS(ruleEndLatLng[1], true)}`,
          meta: {},
          waypoint_type_id: 3,
        },
        activeRoute ? activeRoute.waypoints : null,
      );

      this.setState({ ruleStartLatLng: null, ruleEndLatLng: null });
    }
  };

  toggleMapNav = type => {
    if (this.state.mapNavOpen === type) {
      this.setState({ mapNavOpen: null });
    } else {
      this.setState({
        mapNavOpen: type,
        mapSearchActive: false,
        mapSearchLatLng: null,
        mapSearchXY: null,
        mapSearchWaypoint: null,
        mapSearchWaypointActive: null,
      });
    }
  };

  handleMeteorologyOptions = kind => {
    const { tourContext } = this.props;

    if (this.state.meteorology === kind) {
      this.setState({ meteorology: null });
    } else {
      this.setState({
        meteorology: kind,
        meteorologyLevel: 0,
      });
      this.props.drawer.closeDrawer();
      this.props.drawer.setDrawerRoute('/');
    }

    if (tourContext.dispatchValidation(metrics.events.MENU_MAPA.MAPAS_METEOROLOGICOS.MAPA)) {
      this.setState({ mapNavOpen: null });
    }
  };

  handleBaseMapChange = baseMap => {
    if (this.state.baseMap === baseMap) {
      this.setState({ baseMap: null, meteorology: null });
    } else {
      this.setState({ baseMap, meteorology: null });
    }
  };

  handleChartOptionsFirst = chartOptionsFirst => {
    if (this.state.chartOptionsFirst === chartOptionsFirst) {
      this.setState({ chartOptionsFirst: null, meteorology: null });
    } else {
      this.setState({ chartOptionsFirst, meteorology: null });
    }
  };

  handleChartOptionsSecond = chartOptionsSecond => {
    if (this.state.chartOptionsSecond === chartOptionsSecond) {
      if (!this.state.baseMap) {
        this.forceUpdate();
        return;
      }

      this.setState({ chartOptionsSecond: null, meteorology: null });
    } else {
      this.setState({ chartOptionsSecond, meteorology: null });
    }
  };

  handleZoomIn = () => {
    if (this.state.zoom >= 16) {
      return;
    }

    this.setState({
      zoom: this.state.zoom + 0.65,
      mapSearchActive: false,
      mapSearchLatLng: null,
      mapSearchXY: null,
      mapSearchWaypoint: null,
      mapSearchWaypointActive: null,
      mapNavOpen: null,
    });
  };

  handleZoomOut = () => {
    if (this.state.zoom <= 3) {
      return;
    }

    this.setState({
      zoom: this.state.zoom - 0.65,
      mapSearchActive: false,
      mapSearchLatLng: null,
      mapSearchXY: null,
      mapSearchWaypoint: null,
      mapSearchWaypointActive: null,
      mapNavOpen: null,
    });
  };

  handleFitBrazil = () => {
    this.setState(
      {
        bounds: null,
      },
      () => {
        this.setState(
          {
            bounds: [
              [-33.63, -74.37],
              [5.92, -34.73],
            ],
          },
          () => {
            this.setState({ bounds: null });
          },
        );
      },
    );
  };

  handleFitRoute = () => {
    const { activeRoute } = this.props;

    if (activeRoute && activeRoute.waypoints && activeRoute.waypoints.length > 0) {
      let minLat = activeRoute.waypoints[0].latitude;
      let minLng = activeRoute.waypoints[0].longitude;
      let maxLat = activeRoute.waypoints[0].latitude;
      let maxLng = activeRoute.waypoints[0].longitude;

      for (let i = 1; i < activeRoute.waypoints.length; i++) {
        if (activeRoute.waypoints[i].latitude < minLat) {
          minLat = activeRoute.waypoints[i].latitude;
        }
        if (activeRoute.waypoints[i].latitude > maxLat) {
          maxLat = activeRoute.waypoints[i].latitude;
        }

        if (activeRoute.waypoints[i].longitude < minLng) {
          minLng = activeRoute.waypoints[i].longitude;
        }
        if (activeRoute.waypoints[i].longitude > maxLng) {
          maxLng = activeRoute.waypoints[i].longitude;
        }
      }

      if (activeRoute.waypoints.length === 1) {
        minLat -= 1;
        minLng -= 1;
        maxLat += 1;
        maxLng += 1;
      }

      if (
        activeRoute.waypoints.length === 2 &&
        activeRoute.waypoints[0].latitude === activeRoute.waypoints[1].latitude &&
        activeRoute.waypoints[0].longitude === activeRoute.waypoints[1].longitude
      ) {
        minLat -= 0.5;
        minLng -= 0.5;
        maxLat += 0.5;
        maxLng += 0.5;
      }

      this.setState(
        {
          bounds: [
            [minLat, minLng],
            [maxLat, maxLng],
          ],
        },
        () => {
          this.setState({ bounds: null });
        },
      );
    } else {
      this.setState(
        {
          bounds: [
            [-33.63, -74.37],
            [5.92, -34.73],
          ],
        },
        () => {
          this.setState({ bounds: null });
        },
      );
    }

    this.setState({
      mapSearchActive: false,
      mapSearchLatLng: null,
      mapSearchXY: null,
      mapSearchWaypoint: null,
      mapSearchWaypointActive: null,
      mapNavOpen: null,
    });
  };

  handleViewportChange = event => {
    const { mapSearchActive, mapSearchWaypoint } = this.state;

    if (mapSearchActive) {
      if (mapSearchWaypoint) {
        mapSearchWaypoint.target.setLatLng({
          lat: mapSearchWaypoint.waypoint.latitude,
          lng: mapSearchWaypoint.waypoint.longitude,
        });
      }

      this.setState({
        mapSearchActive: false,
        mapSearchLatLng: null,
        mapSearchXY: null,
        mapSearchWaypoint: null,
        mapSearchWaypointActive: null,
      });
    }
  };

  handleViewportChanged = event => {
    const { center, zoom } = this.state;

    if (!event.center) return;

    if (event.center[0] !== center[0] || event.center[1] !== center[1] || event.zoom !== zoom) {
      this.setState({ center: event.center, zoom: event.zoom });
    }
  };

  handleMapResize = () => {
    const { mapSearchWaypoint } = this.state;

    if (mapSearchWaypoint) {
      mapSearchWaypoint.target.setLatLng({
        lat: mapSearchWaypoint.waypoint.latitude,
        lng: mapSearchWaypoint.waypoint.longitude,
      });
    }

    this.setState({ mapSearchActive: false, mapSearchLatLng: null, mapSearchXY: null, mapSearchWaypointActive: null });
  };

  handleWaypointLocate = (lat, lng, zoom) => {
    this.setState({ center: [lat, lng] });
    this.setState({ zoom: zoom ?? 7 });
  };

  handleWaypointAdd = item => {
    const { addRouteWaypoint, switchRouteWaypoint, activeRoute, drawer } = this.props;
    const { mapSearchWaypoint } = this.state;

    if (!isMobileWidth()) {
      drawer.setDrawerRoute('/route-plan');
    }

    if (mapSearchWaypoint) {
      this.setState({
        mapSearchActive: false,
        mapSearchLatLng: null,
        mapSearchXY: null,
        mapSearchWaypoint: null,
        mapSearchWaypointActive: null,
      });
      return switchRouteWaypoint(mapSearchWaypoint.waypoint, item, activeRoute ? activeRoute.waypoints : null);
    }
    this.setState({
      mapSearchActive: false,
      mapSearchLatLng: null,
      mapSearchXY: null,
      mapSearchWaypointActive: null,
    });

    return addRouteWaypoint(item, activeRoute ? activeRoute.waypoints : null);
  };

  handleWaypointSearchChange = text => {
    this.setState({ mapSearchText: text });
  };

  handleInputWaypointAdd = item => {
    this.handleWaypointAdd(item).then(() => {
      this.handleFitRoute();
    });
  };

  handleInputWaypointFocus = () => {
    this.setState({
      mapSearchActive: false,
      mapSearchLatLng: null,
      mapSearchXY: null,
      mapSearchWaypointActive: null,
      mapNavOpen: null,
    });
  };

  handleWaypointDrag = (target, i) => {
    this.setState({
      mapSearchWaypointActive: { i, pos: target.latlng },
    });
  };

  handleWaypointDragStart = (target, i) => {
    this.setState({
      mapSearchActive: false,
      mapSearchLatLng: null,
      mapSearchXY: null,
    });
  };

  handleWaypointDragEnd = (waypoint, target, clientPos) => {
    this.setState({
      mapSearchActive: true,
      mapSearchWaypoint: { waypoint, target },
      mapSearchLatLng: { lat: target._latlng.lat, lng: target._latlng.lng },
      mapSearchXY: { x: clientPos.x, y: clientPos.y },
      blockMapClick: true,
    });

    setTimeout(() => {
      this.setState({ blockMapClick: false });
    }, 200);
  };

  handleMapSearchClose = () => {
    this.setState({
      mapSearchActive: false,
      mapSearchLatLng: null,
      mapSearchXY: null,
      mapSearchWaypointActive: null,
      mapNavOpen: null,
    });
  };

  handleMobileClosesDrawer = () => {
    const { drawer } = this.props;
    const { innerWidth: width } = window;
    if (width < 520) {
      drawer.setDrawerRoute('/');
    }
  };

  handleMeteorologyClose = () => {
    this.setState({
      mapNavOpen: null,
    });
  };

  handleGenerateNavLog = () => {
    const { activeRoute, authState } = this.props;
    const { chartOptionsFirst, chartOptionsSecond } = this.state;

    const magneticBearings = [];
    for (let i = 0; i < activeRoute.waypoints.length - 1; i++) {
      magneticBearings.push(
        magneticBearing(
          [activeRoute.waypoints[i].latitude, activeRoute.waypoints[i].longitude],
          [activeRoute.waypoints[i + 1].latitude, activeRoute.waypoints[i + 1].longitude],
        ),
      );
    }

    const waypoints = activeRoute.waypoints.map(waypoint => {
      const rtr = {
        name: waypoint.name,
        latitude: waypoint.latitude,
        longitude: waypoint.longitude,
      };

      return rtr;
    });

    const mapLayers = [];

    if (chartOptionsSecond) {
      mapLayers.push(chartOptionsSecond);
    }

    if (chartOptionsFirst) {
      mapLayers.push(chartOptionsFirst);
    }

    const params = [
      `name=${activeRoute.name}`,
      `waypoints=${encodeURI(JSON.stringify(waypoints))}`,
      `magnetic=${encodeURI(JSON.stringify(magneticBearings))}`,
      `charts=${encodeURI(JSON.stringify(mapLayers))}`,
      `token=${authState.token}`,
    ];

    if (activeRoute.ground_speed) {
      params.push(`gs=${activeRoute.ground_speed}`);
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = `${apiUrl()}/navigationRoute/navigationLog?${params.join('&')}`;
    a.target = '_blank';
    a.click();
  };

  handleMeteorologyPickerMoved = (lat, lng) => {
    this.setState({ meteorologyPicker: [lat, lng] });
  };

  handleMeteorologyPickerClosed = () => {
    this.setState({ meteorologyPicker: null });
  };

  render() {
    const {
      center,
      zoom,
      bounds,
      meteorology,
      meteorologyLevel,
      ruleActive,
      ruleStartLatLng,
      ruleEndLatLng,
      ruleDragging,
      mapSearchActive,
      mapSearchText,
      mapSearchWaypointActive,
      mapSearchLatLng,
      mapSearchXY,
      mapNavOpen,
      baseMap,
      chartOptionsFirst,
      chartOptionsSecond,
      meteorologyPicker,
      appFooter,
      appModalDesktop,
      appModalMobile,
      betaBanner,
    } = this.state;

    const { status, drawer, activeRoute, authState } = this.props;

    const { innerWidth: screenWidth } = window;

    const mapLayers = [baseMap];
    if (meteorology) {
      mapLayers.push(meteorology);
    }
    if (chartOptionsFirst) {
      mapLayers.push(chartOptionsFirst);
    }
    if (chartOptionsSecond) {
      mapLayers.push(chartOptionsSecond);
    }

    return (
      <div className="map-main">
        {drawer.drawerRoute === '/route-plan' && (
          <RoutePanel
            onGenerateNavLog={this.handleGenerateNavLog}
            onRouteItemClick={this.handleMapSearchClose}
            onClick={this.handleMeteorologyClose}
            onLocate={this.handleWaypointLocate}
          />
        )}
        {drawer.drawerRoute === '/route-favorites' && (
          <UserRoutes
            onClick={() => {
              this.handleMeteorologyClose();
              this.handleMobileClosesDrawer();
            }}
          />
        )}
        {drawer.drawerRoute === '/market' && <NexMarket onClick={this.handleMeteorologyClose} />}
        {drawer.drawerRoute === '/beta-info' && <BetaInfo onClick={this.handleMeteorologyClose} />}
        {drawer.drawerRoute === '/app' && <AppMobile onClick={this.handleMeteorologyClose} />}
        {drawer.drawerRoute === '/news' && <News onClick={this.handleMeteorologyClose} />}
        {drawer.drawerRoute === '/configs' && (
          <Configs onClick={this.handleMeteorologyClose} fixedStatus={status} onLocate={this.handleWaypointLocate} />
        )}

        {/* {screenWidth > 630 && appFooter && (
          <AppFooter onClose={() => this.setState({ appFooter: false })} onModal={() => this.setState({ appModalDesktop: true })} />
        )}
        {screenWidth > 630 && appModalDesktop && <AppModalDesktop onClose={() => this.setState({ appModalDesktop: false })} />}
        {screenWidth <= 630 && appModalMobile && <AppModalMobile onClose={() => this.setState({ appModalMobile: false })} />}
        */}
        {betaBanner && <BetaBanner onClose={() => this.setState({ betaBanner: false })} />}

        <div className="map-main__map-wrapper">
          <ReactResizeDetector handleWidth handleHeight onResize={this.handleMapResize}>
            <MapComponent
              layers={mapLayers}
              center={center}
              zoom={zoom}
              bounds={bounds}
              mapRef={this.mapRef}
              onMapRef={this.handleMapRef}
              onClick={this.handleMapClick}
              onMouseMove={event => this.handleMapMouseMove(event.latlng)}
              onViewportChange={event => this.handleViewportChange(event)}
              onViewportChanged={event => this.handleViewportChanged(event)}
              rule={{ ruleActive, ruleStartLatLng, ruleEndLatLng, ruleDragging }}
              search={mapSearchLatLng}
              onWaypointDrag={this.handleWaypointDrag}
              onWaypointDragStart={this.handleWaypointDragStart}
              onWaypointDragEnd={this.handleWaypointDragEnd}
              onRuleAddToMap={this.handleRuleAddToMap}
              mapSearchWaypointActive={mapSearchWaypointActive}
              meteorology={meteorology}
              meteorologyLevel={meteorologyLevel}
              meteorologyPicker={meteorologyPicker}
              drawerOpen={drawer && drawer.drawerRoute !== '/'}
              onMeteorologyMapClick={this.handleMeteorologyMapClick}
              onMeteorologyPickerMoved={this.handleMeteorologyPickerMoved}
              onMeteorologyPickerClosed={this.handleMeteorologyPickerClosed}
              shouldBoundsUpdate={activeRoute && activeRoute.waypoints.length > 1}
            />
          </ReactResizeDetector>
        </div>

        <MapSearch
          search={{ mapSearchActive, mapSearchLatLng, mapSearchXY }}
          onLocate={this.handleWaypointLocate}
          onAdd={this.handleWaypointAdd}
          isDrag={mapSearchWaypointActive !== null}
        />

        <WaypointInputSearch
          text={mapSearchText}
          onChange={this.handleWaypointSearchChange}
          onLocate={this.handleWaypointLocate}
          onAdd={this.handleInputWaypointAdd}
          onFocus={this.handleInputWaypointFocus}
        />

        <MapNav
          onRuleUpdate={this.handleRuleUpdate}
          ruleActive={ruleActive}
          meteorology={meteorology}
          onZoomIn={this.handleZoomIn}
          onZoomOut={this.handleZoomOut}
          onFitRoute={this.handleFitRoute}
          onMapOptions={() => this.toggleMapNav(OPTIONS_TYPE.LAYERS)}
          onMeteorologyOptions={() => this.toggleMapNav(OPTIONS_TYPE.METEOROLOGY)}
          mapNavOpen={mapNavOpen}
        />

        {mapNavOpen === OPTIONS_TYPE.LAYERS && (
          <MapOptions
            baseMap={baseMap}
            onChartOptionsFirst={this.handleChartOptionsFirst}
            onChartOptionsSecond={this.handleChartOptionsSecond}
            onBaseMapChange={this.handleBaseMapChange}
            chartOptionsSecond={chartOptionsSecond}
            chartOptionsFirst={chartOptionsFirst}
          />
        )}
        {mapNavOpen === OPTIONS_TYPE.METEOROLOGY && (
          <MeteorologyOptions meteorology={meteorology} onMeteorologyOptions={this.handleMeteorologyOptions} />
        )}

        {meteorology === METEOROLOGY_OPTIONS.WINDS && (
          <MeteorologySlider onChange={value => this.setState({ meteorologyLevel: value })} value={meteorologyLevel} />
        )}

        {meteorology === null && <MapTip />}

        {/* <PromoModal promo="PILOTAPP2022" src={ImageBanner} /> */}

        <DashboardTour />

        <a href="https://beta.app.nexatlas.com">
          <div className="map-main__new-button">Acessar a nova versão</div>
        </a>
      </div>
    );
  }
}

const mapDispatch = {
  getAllWaypointsAction,
  getAllUserWaypointsAction,
  getAllWaypointTypesAction,
  loadUserRoutesAction,
  addRouteWaypoint,
  switchRouteWaypoint,
};

const mapStateToProps = (state, props) => ({
  activeRoute: getActiveRoute(state, props),
  authState: state.auth,
});

export default withTourContext(withDrawer(connect(mapStateToProps, mapDispatch)(MapMain)));
