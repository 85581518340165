/* global mt */
/* global gtag */

import { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';

import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import amplitude from 'amplitude-js';
import LinkedInTag from 'react-linkedin-insight';

import { shallowCheckEqualObjects } from 'utils/compare';

function RoutePlanning(props) {
  const { authState, children } = props;

  const [initialLoaded, setInitialLoaded] = useState(false);
  const userInfoLoaded = useRef(false);
  const previousUser = useRef(null);
  const previousUserProfile = useRef(null);

  const updateUserInfoSingle = useCallback(() => {
    const { user, user_profile } = authState;

    // MAUTIC
    try {
      mt('send', 'pageview', { email: user.email });
    } catch (e) {
      console.error('Error sending pageview to Mautic', e);
    }

    // SENTRY
    try {
      Sentry.configureScope(scope => {
        scope.setUser({ id: user.id, email: user.email });
      });
    } catch (e) {
      console.error('Error sending pageview to Mautic', e);
    }

    // GOOGLE ANALYTICS
    try {
      ReactGA.set({
        userId: user.id,
        dimension1: user_profile.pilot ? 'Piloto' : 'Não piloto',
        dimension2: user_profile.aviation_segment,
        dimension3: user_profile.aircraft_type,
        dimension4: user_profile.self_classification,
      });
    } catch (e) {}

    try {
      gtag('config', 'G-VENWGLQHG3', {
        user_id: user.id,
        custom_map: { dimension1: 'pilot', dimension2: 'segment', dimension3: 'aircraft_type', dimension4: 'extra' },
        linker: {
          accept_incoming: true,
        },
      });

      gtag('set', 'user_properties', {
        pilot: user_profile.pilot ? 'Piloto' : 'Não piloto',
        segment: user_profile.aviation_segment || user_profile.self_classification,
        aircraft_type: user_profile.aircraft_type,
      });

      // Linkedin Insigher
      LinkedInTag.init('1868562', 'dc', false);
    } catch (e) {}
  }, [authState]);

  const updateUserInfo = useCallback(() => {
    const { user, user_profile } = authState;

    // AMPLITUDE
    amplitude.getInstance().setUserId(user.id);
    amplitude.getInstance().setUserProperties({
      id: user.id,
      email: user.email,
      type: user.type,
      created_at: user.created_at,
      trial_to: user.trial_to,
      trial_checked: user.trial_checked,
      plan_id: user.plan_id,
      manager_id: user.manager_id,
      subscription_created: user.subscription_created,
      subscription_updated: user.subscription_updated,
      subscription_status: user.subscription_status,
      subscription_method: user.subscription_method,
      subscription_canceled_reason: user.subscription_canceled_reason,
      ...user_profile,
    });
  }, [authState]);

  useEffect(() => {
    if (!initialLoaded) {
      const { user } = authState;

      console.log('[Analytics] Loading initial analytics');

      // GOOGLE ANALYTICS
      ReactGA.initialize('UA-129952605-1', {
        titleCase: true,
        gaOptions: {
          alwaysSendReferrer: true,
        },
      });

      // FACEBOOK PIXEL
      const advancedMatching = {};
      const options = {
        autoConfig: true,
        debug: false,
      };

      if (user && user.email) {
        advancedMatching.em = user.email;
      }

      ReactPixel.init('348887454047377', advancedMatching, options);
      ReactPixel.pageView();

      setInitialLoaded(true);
    }
  }, [authState, initialLoaded]);

  useEffect(() => {
    if (initialLoaded && authState.user && authState.user_profile && !userInfoLoaded.current) {
      const { user, user_profile } = authState;

      console.log('[Analytics] Loading user data');

      updateUserInfoSingle();
      updateUserInfo();

      previousUser.current = user;
      previousUserProfile.current = user_profile;

      userInfoLoaded.current = true;
    }
  }, [authState, initialLoaded, updateUserInfo, updateUserInfoSingle]);

  useEffect(() => {
    if (!authState.user && !authState.user_profile && userInfoLoaded.current) {
      console.log('[Analytics] Cleaning user data');

      // SENTRY
      Sentry.configureScope(scope => {
        scope.clear();
      });

      // GOOGLE ANALYTICS
      ReactGA.set({
        userId: null,
      });

      // Amplitude
      amplitude.getInstance().clearUserProperties();
      amplitude.getInstance().regenerateDeviceId();

      userInfoLoaded.current = false;
    }
  }, [authState]);

  useEffect(() => {
    if (userInfoLoaded.current) {
      if (
        !shallowCheckEqualObjects(previousUser.current, authState.user) ||
        !shallowCheckEqualObjects(previousUserProfile.current, authState.user_profile)
      ) {
        console.log('[Analytics] Updating user data');
        updateUserInfo();
      }

      previousUser.current = authState.user;
      previousUserProfile.current = authState.user_profile;
    }
  }, [authState, userInfoLoaded, previousUser, previousUserProfile, updateUserInfo]);

  if (!initialLoaded) {
    return null;
  }

  return children;
}

const mapStateToProps = state => ({
  authState: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoutePlanning);
