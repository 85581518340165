import React, { Component } from 'react';

import CloseIcon from 'assets/icons/barras/close.svg';

import './BetaBanner.style.scss';

class BetaBanner extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <div className="beta-banner" onClick={onClose}>
        <div className="beta-banner__content" onClick={e => e.stopPropagation()}>
          <div className="beta-banner__content__header">
            <div className="beta-banner__content__header__close" onClick={onClose}>
              <img src={CloseIcon} alt="X" />
            </div>
          </div>
          <div className="beta-banner__content__body">
            <div className="beta-banner__content__body__text">
              <div className="beta-banner__content__body__text__title__container">
                <h1>Experimente a nova versão do aplicativo web!</h1>
              </div>
              <p>
                Estamos empolgados em apresentar a nova versão do nosso software, cheia de novas funcionalidades e melhorias para você explorar em
                primeira mão.{' '}
                <span role="img" aria-label="rocket icon">
                  🚀
                </span>
              </p>
              <div className="beta-banner__content__body__text__button__container">
                <a href="https://beta.app.nexatlas.com">Acessar a nova versão</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BetaBanner;
